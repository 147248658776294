import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Product-Details.scss';

const ProductDetail = () => {
  const { productId } = useParams(); // Получаем productId из URL
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://api.eureka-glass.ru/api/products/${productId}`);
        setProduct(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]); // Обновляем данные при изменении productId

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading product data.</div>;
  }
  
  const fullImgPath = `/images/products/${product.img_path}`;
  const formattedPrice = `от ${parseFloat(product.price).toLocaleString('ru-RU')} руб/м2`;
  
  return (
    <section className="wrap">
      <Link to="/#catalog" className='prod-link'><span>&#8592;</span> Обратно в каталог</Link>
      <div className="product-detail">
        <div>
          <img src={fullImgPath} alt={product.name} />
        </div>
        <div className='product-detail-content'>
          <h2>{product.name}</h2>
          <p>{product.description}</p>
          <p className='price'>Цена: {formattedPrice}</p>
        </div>
      </div>
    </section>
  );
}

export default ProductDetail;
