import './Faq.scss';
import FaqCard from './FaqCard/FaqCard';

const Faq = () => {
  const faqData = [
    {
      id: 1,
      summary: "Есть ли готовые стеклянные конструкции на вашем складе?",
      text: "Готовых стеклянных изделий на складе нет. Каждая конструкция изготавливается под заказ.",
    },
    {
      id: 2,
      summary: "Насколько прочны конструкции из стекла?",
      text: "Конструкции изготавливаются из закаленного стекла и из триплекса. Закаленное стекло прочнее обычного (сырого) стекла в семь раз. Кроме этого такое стекло не дает острых осколков при маловероятном разрушении. Триплекс — это многослойное стекло, изготовленное из закаленного стекла склеенного между собой полимерным составом. Даже, если триплекс удастся разбить, осколки не разлетаются по всему помещению, а удерживаются на полимерной пленке стекла. Двери, ограждения, навесы, перекрытия, полы, мосты из триплекса безопасны, устойчивы к механическим повреждениям, к ударам, не поддаются воздействию влаги и перепаду температур.",
    },
    {
      id: 3,
      summary: "Какого цвета бывает закаленное стекло?",
      text: "Прозрачное, оптически бесцветное, матовое, тонированное в массе (бронза, серое, синее, зеленое).",
    },
    {
      id: 4,
      summary: "В какие проемы устанавливается стеклянная дверь?",
      text: "Стеклянная дверь устанавливается в проём с чистовой отделкой. Стены имеют облицовку плиткой, оклеены обои, покраска стен и т.п.",
    },
  ]
  return (
    <section class="faq wrap" id="faq">
      <h2 class="headers-h2">Часто задаваемые вопросы</h2>
      {faqData.map(cardData => (
        <FaqCard key={cardData.id} cardData={cardData} />
      ))}
    </section>
  )
}

export default Faq;