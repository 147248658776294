import React, { useState, useEffect } from 'react';
import './Products.scss';
import ProductsCards from './Products-Cards/Products-Cards';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

const Products = () => {
  const [categoryName, setCategoryName] = useState('');
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { categoryId } = useParams(); // Получаем categoryId из URL

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`https://api.eureka-glass.ru/api/products/category/${categoryId}`);
        setCategoryName(response.data.categoryName); // Установка названия категории
        setProductsData(response.data.products);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [categoryId]); // Обновляем данные при изменении categoryId

  return (
    <section className="wrap products">
      <Link to="/#catalog" className='prod-link'><span>&#8592;</span> Обратно в каталог</Link>
      <h2 className="headers-h2">{categoryName}</h2> {/* Используем categoryName */}
      {loading ? (
        <div>Loading...</div>
      ) : (
        <ProductsCards products={productsData} />
      )}
    </section>
  );
}

export default Products;
