// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bheader-contact {
  display: flex;
  gap: 20px;
  margin: 17px 0;
}

@media screen and (max-width: 768px) {
  .bheader-contact {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Before-Header/Before-Header-Contact/Before-Header-Contact.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,SAAA;EACA,cAAA;AAAF;;AAGA;EACE;IACE,sBAAA;IACA,mBAAA;IACA,kBAAA;EAAF;AACF","sourcesContent":["\r\n.bheader-contact {\r\n  display: flex;\r\n  gap: 20px;\r\n  margin: 17px 0;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .bheader-contact {\r\n    flex-direction: column;\r\n    align-items: center;\r\n    text-align: center;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
