import React, { useState, useEffect } from 'react';
import './Catalog-Cards.scss';
import { Link } from 'react-router-dom';
import CatalogCard from './Catalog-Card/Catalog-Card';
import axios from 'axios';

const CatalogCards = () => {
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.eureka-glass.ru/api/cardsData');
        setCardsData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cards data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="catalog-cards">
      {cardsData.map(cardData => (
        <CatalogCard key={cardData.id} cardData={cardData} />
      ))}
      <div className="catalog-card" id="catalog-button">
        <Link to="/#feedback" className="button">Оставить заявку</Link>
      </div>
    </div>
  );
}

export default CatalogCards;
