import './Before-Header-Hr.scss';

const BHeaderHr = () => {
  return (
    <div>
        <hr class="bheader-hr"/>
    </div>
  )
}

export default BHeaderHr;