import './Catalog-Card-Desc.scss';
import CatalogCardDescElem from './Catalog-Card-Desc-Elem/Catalog-Card-Desc-Elem';
import IconTime from 'assets/media/icons/catalog/catalog-time.svg';
import IconPrice from 'assets/media/icons/catalog/catalog-price.svg';

const CatalogCardDesc = ({ cardData }) => {
  const { time, price } = cardData;
  const formattedPrice = `от ${parseFloat(price).toLocaleString('ru-RU')} руб/м2`;
  return (
    <div class="catalog-card-desc">
      <CatalogCardDescElem imgPath={IconTime} text={`от ${time} дней`}/>
      <CatalogCardDescElem imgPath={IconPrice} text={formattedPrice}/>
    </div>
  )
}

export default CatalogCardDesc;