// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Mitr-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Montserrat-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Montserrat-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Mitr-Light";
  src: local("Mitr-Light"),
   url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat-Black"),
   url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
   url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-Semibold"),
   url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB;6DACwD;EACxD,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B;6DAC8D;AAChE;;AAEA;EACE,iCAAiC;EACjC;6DACgE;AAClE;;AAEA;EACE,kCAAkC;EAClC;6DACiE;AACnE;AACA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb","sourcesContent":["@font-face {\n  font-family: \"Mitr-Light\";\n  src: local(\"Mitr-Light\"),\n   url(\"./assets/fonts/Mitr-Light.ttf\") format(\"truetype\");\n  font-weight: normal;\n}\n\n@font-face {\n  font-family: \"Montserrat-Black\";\n  src: local(\"Montserrat-Black\"),\n   url(\"./assets/fonts/Montserrat-Black.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Montserrat-Regular\";\n  src: local(\"Montserrat-Regular\"),\n   url(\"./assets/fonts/Montserrat-Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Montserrat-SemiBold\";\n  src: local(\"Montserrat-Semibold\"),\n   url(\"./assets/fonts/Montserrat-SemiBold.ttf\") format(\"truetype\");\n}\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
