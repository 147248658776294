import React from 'react';
import './Product-Card-Desc.scss';
import IconPrice from 'assets/media/icons/catalog/catalog-price.svg'; 

const ProductCardDesc = ({ cardData }) => {
  const { price } = cardData;
  const formattedPrice = `от ${parseFloat(price).toLocaleString('ru-RU')} руб/м2`;
  return (
    <div className="catalog-card-desc">
      <div className="catalog-card-desc-elem">
        <img src={IconPrice} alt=""/>
        <p className="catalog-card-desc-price">{formattedPrice}</p>
      </div>
    </div>
  )
}

export default ProductCardDesc;
