import './Reviews-Cards.scss';
import ImgReview1 from 'assets/media/images/reviews/review1.jpg';
import ImgReview2 from 'assets/media/images/reviews/review2.jpg';
import ImgReview3 from 'assets/media/images/reviews/review3.jpg';
import ReviewsCard from './Review-Card/Review-Card';

const ReviewsCards = () => {
  const revCardsData = [
    {
      id: 1,
      imgPath: ImgReview1,
      text: "Рекомендую этот магазин. Заказала террасу, подобрали идеальный вариант для меня. Приветливый персонал. Ждала почти месяц, сделали всё чётко по договору и в срок",
    },
    {
      id: 2,
      imgPath: ImgReview2,
      text: "Заказал душевую. Подобрали конструкцию, у меня не стандартная получилась. По обслуживанию и монтажу-вопросов нет, все качественно.Рекомендую!",
    },
    {
      id: 3,
      imgPath: ImgReview3,
      text: "Компания изготовила для меня остекление балконных решеток, по нестандартным размерам. Мастера-монтажники подобрали красивый и недорогой крепеж и очень аккуратно все установили. Одним словом - рекомендую!",
    }
  ];
  return (
    <div class="reviews-cards">
      {revCardsData.map(cardData => (
        <ReviewsCard key={cardData.id} cardData={cardData} />
      ))}  
    </div>
  )
}

export default ReviewsCards;