// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.before-header {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1199px) {
  .before-header {
    flex-direction: column;
    align-items: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Before-Header/Before-Header.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE;IACE,sBAAA;IACA,mBAAA;EACF;AACF","sourcesContent":[".before-header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n@media screen and (max-width: 1199px) {\r\n  .before-header {\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
