import React, { useState, useEffect } from 'react';
import './Works-Cards.scss';
import WorksCard from './Works-Card/Works-Card';
import axios from 'axios';

const WorksCards = () => {
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.eureka-glass.ru/api/worksData');
        setCardsData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cards data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="works-cards">
      {cardsData.map(cardData => (
        <WorksCard key={cardData.id} cardData={cardData} />
      ))}
    </div>
  );
}

export default WorksCards;
