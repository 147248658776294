import WorksCards from './Works-Cards/Works-Cards';
import './Works-Content.scss';

const WorksContent = () => {
  return (
    <section className='wrap'>
      <h2 className='headers-h2'>Наши работы</h2>
      <WorksCards/>
    </section>
  )
};

export default WorksContent;