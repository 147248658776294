import React from 'react';
import './Product-Card.scss';
import { Link } from 'react-router-dom';
import ProductCardDesc from './Product-Card-Desc/Product-Card-Desc';

const ProductCard = ({ cardData }) => {
  const { id, name, img_path } = cardData; // Убираем price из деструктуризации
  const fullImgPath = `/images/products/${img_path}`;
  return (
    <div className="product-card">
      <Link to={`/product/${id}`} className="product-link">
        <img src={fullImgPath} alt={name} className='product-img'/>
        <h3>{name}</h3>
        <ProductCardDesc cardData={cardData} /> 
      </Link>
    </div>
  );
}

export default ProductCard;
