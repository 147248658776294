// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bheader-hr {
  border-top: 1px solid #ccc;
}`, "",{"version":3,"sources":["webpack://./src/components/Before-Header-Hr/Before-Header-Hr.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF","sourcesContent":[".bheader-hr {\r\n  border-top: 1px solid #ccc;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
