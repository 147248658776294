import './Feedback.scss';
import React, { useState } from "react";

const Feedback = () => {
  const [status, setStatus] = useState("Submit");
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Отправка заявки...");
    const { name, phone, email, comment } = e.target.elements;
    let details = {
      name: name.value,
      phone: phone.value,
      email: email.value,
      comment: comment.value,
    };
    let response = await fetch("https://api.eureka-glass.ru/feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("отправить");
    let result = await response.json();
    alert(result.status);
    // Сброс значений полей формы
    name.value = "";
    phone.value = "";
    email.value = "";
    comment.value = "";
  };

  return (
    <section className="feedback" id="feedback">
      <div className="wrap">
        <h2 className="headers-h2">Оставить заявку</h2>
        <div className="feedback-content">
          <div className="feedback-content-col">
            <p>
              Компания "Eureka Glass" - современные стеклянные и зеркальные конструкции, а также индивидуальный подход к каждому Клиенту.
            </p>
            <input type="checkbox" name="" id="" />
            <label htmlFor="checkbox">Разрешаю обработку персональных данных</label>
          </div>
          <div className="feedback-content-col">
            <form onSubmit={handleSubmit}>
              <input type="text" id="name" name="name" placeholder="Ваше имя*" required />
              <input type="text" id="phone" name="phone" placeholder="Ваш телефон*" required />
              <input type="email" id="email" name="email" placeholder="Адрес эл. почты" />
              <input type='text' id="comment" placeholder="Комментарий к заявке" />
              <button type="submit" className='button-submit'>Отправить</button>
              <p className='status-mail'>{status}</p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Feedback;
