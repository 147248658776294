import './Works-Card.scss';

const WorksCard = ({ cardData }) => {
  const { info, img_url } = cardData;
  const fullImgPath = `https://workers.eureka-glass.ru/${img_url}`;
  return (
    <div className="works-card">
        <img src={fullImgPath} alt={info} loading='lazy'/>
        <h3>{info}</h3>
    </div>
    
  )
}

export default WorksCard;