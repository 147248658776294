import './Catalog-Card.scss';
import CatalogCardDesc from './Catalog-Card-Desc/Catalog-Card-Desc';
import { Link } from 'react-router-dom';

const CatalogCard = ({ cardData }) => {
  const { id, name, img_path } = cardData;
  const fullImgPath = `images/catalog/${img_path}`;
  return (
    <div className="catalog-card">
      <Link to={`/category/${id}`} className='catalog-card-link'>
        <img src={fullImgPath} alt={name} />
        <h3>{name}</h3>
      </Link>
      <CatalogCardDesc cardData={cardData} />
    </div>
    
  )
}

export default CatalogCard;