import React, { useState, useEffect } from 'react';
import './Products-Cards.scss';
import ProductCard from './Product-Card/Product-Card';

const ProductsCards = ({ products }) => {
  const [prodCardsData, setProdCardsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setProdCardsData(products);
    setLoading(false);
  }, [products]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="product-cards">
      {prodCardsData.map(cardData => (
        <ProductCard key={cardData.id} cardData={cardData} />
      ))}
    </div>
  );
}

export default ProductsCards;
